<script setup lang="ts">
import type { VoixNumberFieldInterface, VoixTextFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    drawingNumber: VoixNumberFieldInterface
    height: VoixTextFieldInterface
    width: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Shapes Illustrations', group: 'Illustrations', layouts: ['VoixMarketing'] },
  description: '',
  preview: 'SlicesIllustrationsShapes.jpg',
  fields: {
    drawingNumber: {
      type: 'number',
      label: 'Drawing Number (1-360)',
      default: 1,
      min: 1,
      max: 360,
    },
    height: {
      type: 'text',
      label: 'Height',
      default: '500px',
    },
    width: {
      type: 'text',
      label: 'Width',
      default: '500px',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      drawingNumber: {
        value: 1,
      },
    },
  }],
})

const drawing = computed(() => {
  return `/imgs/wireframe-svgs/${props.fields.drawingNumber.value}.svg`
})
</script>

<template>
  <div class="overflow-hidden !bg-transparent">
    <div
      v-voix-background-color class="w-full h-full bg-voix-stroke mx-auto" :style="{
        mask: `url(${drawing})`,
        maskSize: 'contain',
        maskRepeat: 'no-repeat',
        maskPosition: 'center',
        height: fields.height.value || '',
        width: fields.width.value || '',
      }"
    />
  </div>
</template>
